@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
$panel-animation-length: 300ms;
$color-fade-length: 500ms;
$header-focus-border-width: 1px;


$tertiary-panel-bg-color: $color-brand-tertiary;
$tertiary-border-color: $sb-pale-gray;
$tertiary-header-bg-color: $sb-pale-gray;
$tertiary-header-bg-hover-color: $sb-warm-gray-light;
$gray-header-bg-color: $color-gray-s10;
$gray-panel-bg-color: $color-brand-tertiary;
$footer-gray-panel-bg-color: $color-gray-s05;
$footer-gray-header-bg-color: $color-gray-s05;


$text-color: $color-black;
$control-text-color: $brand-royal-blue;
$alt-text-color: $color-black-alt;

[data-sparta-container] {
  .squeezebox-wrapper {
    margin-bottom: $spacing-16;

    > .squeezebox-group-control-wrapper {
      text-align: right;

      .squeezebox-group-control {
        color: $control-text-color;
        font-size: 16px;
        width: 100%;

                &.spa-btn--link {
          width: auto;
        }
      }

      > .squeezebox-link-divider {
        color: $control-text-color;
      }
    }

    &.all-panels-open {
      > .squeezebox-group-control-wrapper {
        > .squeezebox-group-control.squeezebox-expand-all-link,
        > .squeezebox-link-divider {
          display: none;
        }
      }
    }

    &.all-panels-closed {
      > .squeezebox-group-control-wrapper {
        > .squeezebox-group-control.squeezebox-collapse-all-link,
        > .squeezebox-link-divider {
          display: none;
        }
      }
    }

    .squeezebox-panels {
      &.spa-has-icon {
        padding-bottom: 10px;
        padding-top: 10px;

        .spa-squeezebox-subheading {
          position: relative;
          left: 33px;
        }
      }
      .squeezebox-panel {
        &.spa-squeezebox-panel--incomplete .gray,
        &.spa-squeezebox-panel--incomplete .footer-gray,
        .spa-squeezebox-panel--incomplete.spa-inner-panel .gray,
        .spa-squeezebox-panel--incomplete.spa-inner-panel .footer-gray  {
          .spa-squeezebox-icon {
            background-image: icon(incomplete, (color: $color-gray));
          }
        }
        &.spa-squeezebox-panel--complete,
        .spa-squeezebox-panel--complete.spa-inner-panel {
          .spa-squeezebox-icon {
            background-image: icon(success, (color: $color-secondary-three-dark));
          }
        }
        &.spa-squeezebox-panel--incomplete,
        .spa-squeezebox-panel--incomplete.spa-inner-panel {
          .spa-squeezebox-icon {
            background-image: icon(incomplete, (color: $color-gray-s70));
          }
        }
        &.spa-squeezebox-panel--error,
        .spa-squeezebox-panel--error.spa-inner-panel {
          .spa-squeezebox-icon {
            background-image: icon(warning, (color: $color-regal-one));
          }

          .spa-squeezebox-subheading {
            color: $color-regal-one;
          }
        }
        &:not(.spa-squeezebox-panel--complete,
        .spa-squeezebox-panel--incomplete,
        .spa-squeezebox-panel--error) {
          .spa-squeezebox-icon {
            background-image: none;
          }
        }

        .spa-squeezebox-subheading {
          font-size: 12px;
          color: $color-gray;
          font-weight: $type-weight-regular;
          display: block;
        }

        .panel-header {
          border: 1px solid transparent;
          border-bottom-color: $color-gray-s20;
          color: $text-color;
          cursor: pointer;
          display: block;
          font-weight: normal;
          line-height: $spacing-24;
          margin: 0;
          outline: 0;
          padding: $spacing-12 66px $spacing-12 $spacing-16;
          position: relative;
          text-decoration: none;
          transition: background-color $color-fade-length;
          user-select: none;

          &:hover,
          &:focus {
            border: $header-focus-border-width solid $color-royal-one;

            .spa-squeezebox-arrow {
              background-image: icon(chevronDown, (color: $color-royal-one));
            }
          }

          .spa-squeezebox-arrow {
            background-image: icon(chevronDown, (color: $color-gray));
            height: 24px;
            margin: auto 0;
            position: absolute;
            right: $spacing-12;
            top: $spacing-12;
            transition: $spa-standard-transition;

            -webkit-transition: $spa-standard-transition;
            -moz-transition: $spa-standard-transition;
            -ms-transition: $spa-standard-transition;
            width: 24px;

            &:hover,
            &:focus {
              background-image: icon(chevronDown, (color: $color-royal-one));
              border: 0;
            }
          }

          &.gray {
            background-color: $gray-header-bg-color;
          }

          &.footer-gray {
            background-color: $footer-gray-header-bg-color;
          }

                    &.spa-btn--link {
            width: 100%;
            text-align: left;
          }

          .spa-squeezebox-icon {
            display: inline-flex;
            width: 24px;
            height: 24px;
            position: relative;
            margin-right: 9px;
            margin-bottom: -6px;
          }
        }

        .panel-content {
          background-color: $tertiary-panel-bg-color;
          border-bottom: 1px $color-gray-s20 solid;
          color: $alt-text-color;
          margin: 0;
          overflow: hidden;
          padding: $spacing-24 $spacing-16 $spacing-24;
          transition: height $panel-animation-length cubic-bezier(0, 1, 0.5, 1);

          -webkit-transition: height $panel-animation-length cubic-bezier(0, 1, 0.5, 1);
          -moz-transition: height $panel-animation-length cubic-bezier(0, 1, 0.5, 1);
          -ms-transition: all $panel-animation-length cubic-bezier(0, 1, 0.5, 1);

          p,
          ul,
          ol {
            &:last-child {
              margin-bottom: 0;
            }
          }

          &.footer-gray {
            background-color: $footer-gray-panel-bg-color;
          }


          .content {
            .row,
            .column {
              padding: 0;
            }

            .column:focus {
              outline: none;
              border: none;
            }

            p,
            ul,
            ol {
              margin-left: 40px;

              li {
                margin-bottom: 10px;

                &:last-child{
                  margin-bottom: 0;
                }
              }
            }

            > :last-child, > .row .columns > :last-child {
              margin-bottom: 0;
            }
          }
        }

        &.active {
          > .panel-header {
            border-bottom-color: transparent;
            font-weight: bold;

            &:hover,
            &:focus {
              border-color: $color-royal-one;
            }

            .spa-squeezebox-arrow {
              transform: rotate(180deg);
            }
          }
        }
      }
    }


    .squeezebox-panel__spinner {
      text-align: center;

      &-icon {
        border: 1px solid transparent;
        margin: 0;

        &:focus,
        &:hover {
          border-color: transparent;
        }
      }

      &--hide {
        display: none;
      }
    }
  }
}
